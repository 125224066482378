<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-xl"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <div style="position:absolute;left:-32px; padding:6px 8px 6px 8px;box-shadow:-4px 5px 5px 0px rgb(0 0 0 / 25%) !important" class="bg-white text-dark">
            <feather-icon
                v-b-tooltip.hover.right="'Fermer la création/modification du ticket'"
                class="cursor-pointer"
                icon="XIcon"
                size="18"
                @click="hide"
            />
          </div>
          <div
            v-if="ticketLocal.statut && ticketLocal.id"
            class="d-flex align-items-center"
          >
            <h4 class="mt-1 font-weight-bolder">Modifier le ticket</h4>
          </div>

          <h4
            v-else
            class="mt-1 font-weight-bolder"
          >
            Créer un Ticket
          </h4>
          <div>
            <feather-icon
              v-show="ticketLocal.id"
              v-b-tooltip.hover.left="'Supprimer le ticket'"
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              @click="$emit('remove-ticket'); hide();"
            />
            <feather-icon
                v-if="isAdmin"
                v-b-tooltip.hover.right="'Fermer la création/modification du ticket'"
                class="cursor-pointer ml-2 d-md-none d-inline"
                icon="XIcon"
                size="18"
                @click="hide"
            />
          </div>
        </div>
        <b-tabs
          v-if="ticketLocal.statut && ticketLocal.id"
          fill
        >
          <b-tab active>
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Détails</span>
            </template>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <!-- Form -->
              <b-form
                class="py-1 px-4"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >

                <!-- Title -->
                <validation-provider
                  #default="validationContext"
                  name="Title"
                  rules="required"
                >
                  <b-form-group
                    label="Titre"
                    label-for="ticket-titre"
                    label-class="font-weight-bold text-dark"
                    class="mt-1"
                  >
                    <b-form-input
                      id="ticket-titre"
                      v-model="ticketLocal.titre"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Titre du ticket"
                    />

                    <b-form-invalid-feedback>
                      Titre du ticket obligatoire
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                    label="Description"
                    label-for="ticket-description"
                    label-class="font-weight-bold text-dark"
                    class="mt-2"
                >
                  <quill-editor
                      id="quil-content"
                      v-model="ticketLocal.description"
                      :options="editorOption"
                      class="border-bottom-0"
                  />
                  <div
                      id="quill-toolbar"
                      class="d-flex justify-content-end border-top-0"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                    <button class="ql-strike" />
                    <button
                        class="ql-list"
                        value="bullet"
                    />
                  </div>
                </b-form-group>

                <validation-provider
                  #default="validationContext"
                  name="Service"
                >
                  <b-form-group
                    label="Service"
                    label-for="ticket-extra-field"
                    label-class="font-weight-bold"
                    class="font-weight-bold mt-2"

                  >
                    <b-form-input
                      id="ticket-extra-field"
                      v-model="ticketLocal.extra_field"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Service"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                    #default="validationContext"
                    name="Chambre"
                >
                  <b-form-group
                      label="Chambre"
                      label-for="ticket-chambre"
                      label-class="font-weight-bold"
                      class="font-weight-bold mt-2"

                  >
                    <b-form-input
                        id="ticket-chambre"
                        v-model="ticketLocal.chambre"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Chambre"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="Priorite"
                  rules="required"
                >
                  <b-form-group
                    label="Priorité"
                    label-for="ticket-priorite"
                    label-class="font-weight-bold text-dark mt-2"
                  >
                    <v-select
                      v-model="ticketLocal.priorite"
                      v-b-tooltip.hover.top="'Changer la priorité du ticket'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="option => option.id"
                      :options="priorites"
                      :filterable="false"
                      :searchable="false"
                      :clearable="false"
                      input-id="priorite"
                      label="titre"
                      :class="ticketLocal.id ? 'w-100 bg-white priorite-selector' : 'w-100 bg-white'"
                    >
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!ticketLocal.priorite"
                          v-bind="attributes"
                          v-on="events"
                        >
                      </template>
                      <template #option="{ titre, couleur }">
                        <div>
                          <span
                            class="bullet bullet-sm mr-1"
                            :style="`background-color:${couleur}`"
                          />
                          <span>{{ titre }}</span>
                        </div>
                      </template>

                      <template #selected-option="{ titre, couleur }">
                        <div>
                          <span
                            class="bullet bullet-sm mr-1"
                            :style="`background-color:${couleur}`"
                          />
                          <span>{{ titre }}</span>
                        </div>
                      </template>
                    </v-select>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                    label="Statut"
                    label-for="ticket-statut"
                    label-class="font-weight-bold text-dark"
                    class="mt-1"
                >
                  <v-select
                      v-if="ticketLocal.statut && ticketLocal.id"
                      v-model="ticketLocal.statut"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statuts"
                      :filterable="false"
                      :searchable="false"
                      input-id="statut"
                      label="titre"
                      :class="ticketLocal.id ? 'w-100 bg-white statut-selector text-white cursor-pointer' : 'w-100 bg-white statut-selector'"
                      :style="ticketLocal.id ? 'background-color:'+ ticketLocal.statut.couleur+' !important;' : ''"
                      :clearable="false"
                  />

                </b-form-group>


                <!-- Form Actions -->
                <div class="d-flex mt-5">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ ticketLocal.id ? 'Modifier' : 'Créer le ticket ' }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Réinitialiser
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-tab>
          <b-tab @click="scrollToBottomInChatLog">
            <template #title>
              <feather-icon
                icon="MessageSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Observations ({{ ticketLocal.commentaires ? ticketLocal.commentaires.length : '0' }})</span>
            </template>
            <section class="chat-app-window">
              <vue-perfect-scrollbar
                ref="refChatLogPSComment"
                :settings="perfectScrollbarSettings"
                class="user-chats scroll-area"
                style="height:75vh"
              >
                <div
                  v-if="ticketLocal.commentaires && ticketLocal.commentaires.length > 0"
                  class="chats"
                >
                  <div
                    v-for="msgGrp in ticketLocal.commentaires"
                    :key="msgGrp.id"
                    class="chat"
                    :class="'chat-left'"
                  >
                    <div class="chat-avatar d-none d-sm-inline">
                      <b-avatar
                        v-b-tooltip.hover
                        size="36"
                        class="avatar-border-2 box-shadow-1"
                        variant="primary"
                        :text="avatarText(msgGrp.created_by.name)"
                        :title="msgGrp.created_by.name"
                      />
                    </div>
                    <div class="chat-body">
                      <div
                        class="chat-content"
                      ><small>{{ msgGrp.created_by.name }}, le {{ formatDateTime(msgGrp.created_at, { month: 'long', day: 'numeric'}) }} à {{ formatDateTime(msgGrp.created_at, { hour: 'numeric', minute: 'numeric'}) }}</small>
                        <p class="font-weight-bold mt-1">
                          {{ msgGrp.contenu }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  Pas d'observation
                </div>
              </vue-perfect-scrollbar>
              <b-form
                class="chat-app-form"
                @submit.prevent="sendMessage"
              >
                <b-input-group class="input-group-merge form-send-message mr-1 w-100">
                  <b-form-input
                    v-model="chatInputMessage"
                    placeholder="Contenu de l'observation"
                  />
                </b-input-group>
                <b-button
                  variant="primary"
                  type="submit"
                >
                  Ajouter
                </b-button>
              </b-form>
            </section>
          </b-tab>
          <b-tab @click="scrollToBottomInHistorique">
            <template #title>
              <feather-icon
                icon="ClockIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Historique</span>
            </template>
            <section class="chat-app-window">
              <vue-perfect-scrollbar
                ref="refChatLogPSHistorique"
                :settings="perfectScrollbarSettings"
                class="scroll-area p-3"
                style="height:75vh"
              >
                <div
                  v-if="ticketLocal.historique && ticketLocal.historique.length > 0"
                  class=""
                >
                  <app-timeline>
                    <app-timeline-item
                      v-for="item in ticketLocal.historique"
                      :key="item.id"
                      :title="item.contenu"
                      :time="'le ' + formatDateTime(item.created_at, { month: 'long', day: 'numeric'}) + ' à ' + formatDateTime(item.created_at, { hour: 'numeric', minute: 'numeric'})"
                    />
                  </app-timeline>
                </div>
                <div v-else>
                  Pas d'historique
                </div>
              </vue-perfect-scrollbar>
            </section>
          </b-tab>
        </b-tabs>
        <validation-observer
          v-else
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Titre"
                label-for="ticket-titre"
              >
                <b-form-input
                  id="ticket-titre"
                  v-model="ticketLocal.titre"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Titre du ticket"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Service"
            >
              <b-form-group
                label="Service"
                label-for="ticket-extra-field"
              >
                <b-form-input
                  id="ticket-extra-field"
                  v-model="ticketLocal.extra_field"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Service"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
                #default="validationContext"
                name="Chambre"
            >
              <b-form-group
                  label="Chambre"
                  label-for="ticket-chambre"
              >
                <b-form-input
                    id="ticket-chambre"
                    v-model="ticketLocal.chambre"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Chambre"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!--Statut -->
            <!-- Description -->
            <b-form-group
              label="Description"
              label-for="ticket-description"
            >
              <quill-editor
                id="quil-content"
                v-model="ticketLocal.description"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>

            <validation-provider
              #default="validationContext"
              name="Priorite"
              rules="required"
            >
              <b-form-group
                label="Priorité"
                label-for="ticket-priorite"
              >
                <v-select
                  v-model="ticketLocal.priorite"
                  v-b-tooltip.hover.top="'Changer la priorité du ticket'"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="option => option.id"
                  :options="priorites"
                  :filterable="false"
                  :searchable="false"
                  :clearable="false"
                  input-id="priorite"
                  label="titre"
                  :class="ticketLocal.id ? 'w-100 bg-white priorite-selector' : 'w-100 bg-white'"
                >
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="!ticketLocal.priorite"
                      v-bind="attributes"
                      v-on="events"
                    >
                  </template>
                  <template #option="{ titre, couleur }">
                    <div>
                      <span
                        class="bullet bullet-sm mr-1"
                        :style="`background-color:${couleur}`"
                      />
                      <span>{{ titre }}</span>
                    </div>
                  </template>

                  <template #selected-option="{ titre, couleur }">
                    <div>
                      <span
                        class="bullet bullet-sm mr-1"
                        :style="`background-color:${couleur}`"
                      />
                      <span>{{ titre }}</span>
                    </div>
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ ticketLocal.id ? 'Modifier' : 'Créer le ticket ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Réinitialiser
              </b-button>
            </div>
          </b-form>
        </validation-observer>
        <!-- Body -->
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BInputGroup, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BTabs, BTab, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText, formatDateTime } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { nextTick, ref, toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useTicketHandler from './useTicketHandler'
import store from '@/store'
import moment from "moment-timezone";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BAvatar,
    BTabs,
    BTab,
    BFormInvalidFeedback,

    AppTimeline,
    AppTimelineItem,
    // 3rd party packages
    vSelect,
    quillEditor,

    VuePerfectScrollbar,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    clearTicketData: {
      type: Function,
      required: true,
    },
    statuts: {
      type: Array,
      required: false,
    },
    priorites: {
      type: Array,
      required: false,
    },
    defaultPriorite: {
      type: Object,
      required: false,
    },
    traites: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, context) {
    const {
      ticketLocal,
      resetTicketLocal,

      // UI
      assigneeOptions,
      onSubmit,
      statutOptions,
      prioriteOptions,
      resolveAvatarVariant,
    } = useTicketHandler(toRefs(props), context.emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTicketLocal, props.clearTicketData)

    const userData = JSON.parse(localStorage.getItem('userData'))
    const isAdmin = Array.isArray(userData.roles) && (userData.roles.includes('ROLE_ADMIN') || userData.roles.includes('ROLE_SUPERADMIN'))

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Détails du ticket',
    }

    const chatInputMessage = ref('')

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const refChatLogPSComment = ref(null)

    const refChatLogPSHistorique = ref(null)

    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPSComment.value.$el || refChatLogPSComment.value
      setTimeout(() => {
        scrollEl.scrollTop = scrollEl.scrollHeight
      }, 1000)
    }
    const scrollToBottomInHistorique = () => {
      const scrollEl = refChatLogPSHistorique.value.$el || refChatLogPSHistorique.value
      setTimeout(() => {
        scrollEl.scrollTop = scrollEl.scrollHeight
      }, 1000)
    }

    /* setTimeout(() => {
      scrollToBottomInChatLog()
    }, 3000) */

    const commentaires = ref(ticketLocal.value.commentaires)

    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        ticketId: ticketLocal.value.id,
        message: chatInputMessage.value,
      }
      store.dispatch('app-tickets/postObservation', payload)
        .then(response => {
          commentaires.value = response.data.commentaires
          ticketLocal.value = response.data
          // Reset send message input value
          chatInputMessage.value = ''
          context.root.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Observation ajoutée avec succès',
              variant: 'success',
              text: '',
            },
          })
          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })
    }

    return {
      // Add New
      ticketLocal,
      onSubmit,
      assigneeOptions,
      statutOptions,
      prioriteOptions,

      perfectScrollbarSettings,
      refChatLogPSComment,
      refChatLogPSHistorique,
      commentaires,
      sendMessage,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      chatInputMessage,

      // Filter/Formatter
      avatarText,
      formatDateTime,
      scrollToBottomInChatLog,
      scrollToBottomInHistorique,

      isAdmin,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
