import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTickets(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/ticket/all', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTicket(ctx, ticketData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/ticket/add', ticketData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTicket(ctx, { ticket }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/ticket/edit', ticket)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTicketStatus(ctx, { ticket, status }) {
      console.log('ticket')
      console.log(ticket)
      console.log('status')
      console.log(status)
      return new Promise((resolve, reject) => {
        axios
          .post('/api/ticket/edit-status', { ticket, status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTicket(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/ticket/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStatuts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/statut/all', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPriorites(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/priorite/all', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postObservation(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/ticket/${payload.ticketId}/observation/add`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
