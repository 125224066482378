<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              {{ $t('Liste_sidebar_left_creer_un_ticket') }}
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area pb-3"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in ticketFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                class="d-flex justify-content-between align-items-center"
                @click="$emit('close-left-sidebar')"
              >
                <div>
                  <feather-icon
                    :icon="filter.icon"
                    size="18"
                    class="mr-75"
                  />
                  <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                </div>
                <small class="">{{ filter.digit.toString() }}</small>
              </b-list-group-item>
            </b-list-group>

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Filtrer par  statuts
              </h6>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="statut in ticketStatuts"
                :key="statut.titre + $route.path"
                :to="statut.route"
                :active="isDynamicRouteActive(statut.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span>{{ statut.titre }}</span>
              </b-list-group-item>
            </b-list-group>

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Filtrer par priorités
              </h6>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="priorite in ticketPriorites"
                :key="priorite.titre + $route.path"
                :to="priorite.route"
                :active="isDynamicRouteActive(priorite.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :style="`background-color:${priorite.couleur}`"
                />
                <span>{{ priorite.titre }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
          <div class="footer-version">
            Version 1.6.2 - Altismedia 2022
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    ticketStatuts: {
      type: Array,
      required: true,
    },
    ticketPriorites: {
      type: Array,
      required: true,
    },
    esttraites: {
      type: Number,
      required: false,
    },
    atraiter: {
      type: Number,
      required: false,
    },
    encours: {
      type: Number,
      required: false,
    },
    all: {
      type: Number,
      required: false,
    },
    resolus: {
      type: Number,
      required: false,
    },
    tickets: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18nUtils()
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const {
      atraiter, esttraites, encours, resolus, all, tickets,
    } = toRefs(props)

    const ticketFilters = ref([
      {
        title: 'Tous les tickets', digit: all.value, icon: 'AlignJustifyIcon', route: { name: 'apps-tickets' },
      },
      {
        title: 'A traiter', digit: atraiter.value, icon: 'ActivityIcon', route: { name: 'apps-tickets-filter', params: { filter: 'a-traiter' } },
      },
      {
        title: 'En cours', digit: encours.value, icon: 'LoaderIcon', route: { name: 'apps-tickets-filter', params: { filter: 'en-cours' } },
      },
      {
        title: 'Cloturés', digit: resolus.value, icon: 'CheckIcon', route: { name: 'apps-tickets-filter', params: { filter: 'clotures' } },
      },
    ])

    watch(() => esttraites.value, (first, second) => {
      ticketFilters.value = [
        {
          title: 'Tous les tickets', digit: all.value, icon: 'AlignJustifyIcon', route: { name: 'apps-tickets' },
        },
        {
          title: 'A traiter', digit: atraiter.value, icon: 'ActivityIcon', route: { name: 'apps-tickets-filter', params: { filter: 'a-traiter' } },
        },
        {
          title: 'En cours', digit: encours.value, icon: 'LoaderIcon', route: { name: 'apps-tickets-filter', params: { filter: 'en-cours' } },
        },
        {
          title: 'Cloturés', digit: resolus.value, icon: 'CheckIcon', route: { name: 'apps-tickets-filter', params: { filter: 'clotures' } },
        },
      ]
    })

    watch(() => props.all, (first, second) => {
      ticketFilters.value = [
        {
          title: 'Tous les tickets', digit: all.value, icon: 'AlignJustifyIcon', route: { name: 'apps-tickets' },
        },
        {
          title: 'A traiter', digit: atraiter.value, icon: 'ActivityIcon', route: { name: 'apps-tickets-filter', params: { filter: 'a-traiter' } },
        },
        {
          title: 'En cours', digit: encours.value, icon: 'LoaderIcon', route: { name: 'apps-tickets-filter', params: { filter: 'en-cours' } },
        },
        {
          title: 'Cloturés', digit: resolus.value, icon: 'CheckIcon', route: { name: 'apps-tickets-filter', params: { filter: 'clotures' } },
        },
      ]
    })

    watch(() => props.encours, (first, second) => {
      ticketFilters.value = [
        {
          title: 'Tous les tickets', digit: all.value, icon: 'AlignJustifyIcon', route: { name: 'apps-tickets' },
        },
        {
          title: 'A traiter', digit: atraiter.value, icon: 'ActivityIcon', route: { name: 'apps-tickets-filter', params: { filter: 'a-traiter' } },
        },
        {
          title: 'En cours', digit: encours.value, icon: 'LoaderIcon', route: { name: 'apps-tickets-filter', params: { filter: 'en-cours' } },
        },
        {
          title: 'Cloturés', digit: resolus.value, icon: 'CheckIcon', route: { name: 'apps-tickets-filter', params: { filter: 'clotures' } },
        },
      ]
    })

    watch(() => props.atraiter, (first, second) => {
      ticketFilters.value = [
        {
          title: 'Tous les tickets', digit: all.value, icon: 'AlignJustifyIcon', route: { name: 'apps-tickets' },
        },
        {
          title: 'A traiter', digit: atraiter.value, icon: 'ActivityIcon', route: { name: 'apps-tickets-filter', params: { filter: 'a-traiter' } },
        },
        {
          title: 'En cours', digit: encours.value, icon: 'LoaderIcon', route: { name: 'apps-tickets-filter', params: { filter: 'en-cours' } },
        },
        {
          title: 'Cloturés', digit: resolus.value, icon: 'CheckIcon', route: { name: 'apps-tickets-filter', params: { filter: 'clotures' } },
        },
      ]
    })

    return {
      perfectScrollbarSettings,
      ticketFilters,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
