<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <!-- select 2 demo -->
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex flex-lg-row align-items-center justify-content-between bg-white flex-column">

        <!-- Toggler -->
        <div class="px-3 d-flex">
          <div class="sidebar-toggle d-block d-lg-none ml-1 px-1">
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              style="position: absolute;left: 10px;"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>
          <h3 class="font-weight-bolder mb-25 fs-5">
            {{ $t('Liste_sidebar_left_liste_les_tickets') }}
          </h3>
        </div>
        <div class="d-flex justify-content-end align-items-center mr-md-3">
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            v-b-tooltip.hover.top="$t('Liste_Header_Modifier_periode')"
            :auto-apply="true"
            :locale-data="daterangepickerlocale"
            :ranges="ranges"
            @update="onChangeYear"
          >
            <template
              v-slot:input="picker"
              class="row-filter-calendar"
            >
              <feather-icon
                icon="CalendarIcon"
                class="icon-filter-calendar text-muted mr-1 d-md-inline d-none"
              />du {{ moment(picker.startDate).format('DD MMMM YYYY') }} au {{ moment(picker.endDate).format('DD MMMM YYYY') }} <feather-icon
                icon="ChevronDownIcon"
                class="ml-1 icon-filter-calendar"
              />
            </template>
          </date-range-picker>
          <!--          <div
            v-b-tooltip.hover.top="$t('Liste_Header_Rafraichir')"
            class="btn btn-flat-secondary text-dark btn-sm"
            @click.stop="openFilterModal(ticket)"
          >
            <feather-icon
              icon="FilterIcon"
              size="14"
              class="icon-filter-calendar d-md-inline d-none text-muted mr-1"
            /> Filtrer <feather-icon
              icon="ChevronDownIcon"
              class="ml-1 icon-filter-calendar"
            />
          </div>-->
          <div
            v-b-tooltip.hover.top="$t('Liste_Header_Rafraichir')"
            class="btn btn-flat-secondary btn-icon ml-1"
            @click="handleTimeExpire"
          >
            <feather-icon
              icon="RefreshCwIcon"
              size="14"
              class="cursor-pointer text-dark"
            />
          </div>
          <div
            v-b-tooltip.hover.top="'Vue compacte/détaillée'"
            class="btn btn-flat-secondary btn-icon ml-1"
            @click="changeViewMode"
          >
            <feather-icon
              :icon="listViewMode === 'compact' ? 'ListIcon' : 'ServerIcon'"
              size="14"
              class="cursor-pointer text-dark"
            />
          </div>
        </div>
      </div>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
        @ps-scroll-y="onScroll"
      >
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center bg-dark d-flex align-items-center mt-5 spinner-loading">
              <b-spinner
                small
                variant="white"
                class="mr-1"
              />
              <span class="text-center text-white">Chargement...</span>
            </div>
          </template>
          <!-- Todo List -->
        </b-overlay>
        <draggable
          v-if="listViewMode === 'detailed'"
          v-model="tickets"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="ticket in tickets"
            :key="ticket.id"
            class="todo-item"
            :class="{ 'completed': ticket.statut.est_resolu }"
            :style="'padding-left:0px'"
            @click="handleTicketClick(ticket)"
          >
            <div class="todo-title-wrapper d-flex flex-column p-0">
              <div class="todo-title-area ">
                <div class="d-flex w-100">
                  <div class="title-wrapper flex-column justify-content-center align-items-start w-100">
                    <span class="todo-title">{{ ticket.titre }}</span>
                    <span
                      :v-if="ticket.description"
                      class="todo-subtitle"
                    >{{ ticket.description | stripHTML | str_limit(100) }}</span>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    style="width:200px"
                  >
                    <div
                      v-b-tooltip.hover.left="'Modifier le statut'"
                      class="todo-status"
                      :style="'background-color:' + ticket.statut.couleur"
                      @click.stop="openStatusModal(ticket)"
                    >
                      {{ ticket.statut.titre }} <feather-icon
                        v-if="isAdmin"
                        icon="ChevronDownIcon"
                        size="16"
                      />
                    </div>
                    <small
                      v-b-tooltip.hover.left="'Ajouté le ' + formatDateTime(ticket.created_at, { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'})"
                      class="todo-date text-left"
                    ><feather-icon
                       icon="CalendarIcon"
                       class="icon-status"
                       size="12"
                     />
                      <time-ago
                        :refresh="60"
                        :datetime="ticket.created_at"
                        locale="fr"
                        long
                        tooltip
                      />
                    </small>
                    <small
                      v-b-tooltip.hover.left="'Créé par ' + ticket.created_by.prenom + ' ' + ticket.created_by.nom"
                      class="todo-assigne text-left"
                    ><feather-icon
                      icon="UserIcon"
                      class="icon-status"
                      size="12"
                    /> {{ ticket.created_by.prenom }} {{ ticket.created_by.nom }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="todo-item-action table-responsive mb-0 d-flex justify-content-between">
                <div class="d-flex">
                  <div class="px-1 border-action">
                    <b-media
                      no-body
                      class="p-0 m-0"
                    >
                      <b-media-body class="d-flex flex-column justify-content-center align-items-start">
                        <span class="todo-title-meta">Priorité</span>
                        <p
                          class="mb-0 todo-text-meta"
                          :style="`color:`+ ticket.priorite.couleur"
                        >
                          {{ ticket.priorite.titre }}
                        </p>
                      </b-media-body>
                    </b-media>
                  </div>
                  <div class="px-1 pr-5 border-action">
                    <b-media
                      no-body
                      class="p-0 m-0"
                    >
                      <b-media-body class="d-flex flex-column justify-content-center align-items-start">
                        <span class="todo-title-meta fixed">Service</span>
                        <p class="mb-0 todo-text-meta">
                          {{ ticket.extra_field }}
                        </p>
                      </b-media-body>
                    </b-media>
                  </div>
                  <div class="px-1 pr-5 border-action">
                    <b-media
                      no-body
                      class="p-0 m-0"
                    >
                      <b-media-body class="d-flex flex-column justify-content-center align-items-start">
                        <span class="todo-title-meta fixed">Chambre</span>
                        <p class="mb-0 todo-text-meta">
                          {{ ticket.chambre }}
                        </p>
                      </b-media-body>
                    </b-media>
                  </div>
                  <div class="px-1">
                    <b-media
                      no-body
                      class="p-0 m-0"
                    >

                      <b-media-body class="d-flex flex-column justify-content-center align-items-start">
                        <span class="todo-title-meta">Traité par</span>
                        <p
                          v-if="ticket.assigne_user"
                          class="mb-0 todo-text-meta"
                        >
                          {{ ticket.assigne_user.nom }} {{ ticket.assigne_user.prenom }}
                        </p>
                        <p
                          v-if="!ticket.assigne_user"
                          class="mb-0 todo-text-meta text-muted"
                        >
                          ticket non traité
                        </p>
                      </b-media-body>
                    </b-media>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="px-1 border-action">
                    <b-media
                      v-b-tooltip.hover.left="ticket.commentaires.length + ' pièces jointes'"
                      no-body
                      class="p-0 m-0"
                    >
                      <b-media-aside
                        vertical-align="center"
                        style="margin-right:5px;"
                      >
                        <feather-icon
                          icon="PaperclipIcon"
                          size="16"
                        />
                      </b-media-aside>

                      <b-media-body class="d-flex flex-column justify-content-center align-items-start">
                        <p
                          class="mb-0 pb-0 todo-text-meta"
                          style="margin-top:2px;"
                        >
                          0
                        </p>
                      </b-media-body>
                    </b-media>
                  </div>
                  <div class="px-1 ">
                    <b-media
                      v-b-tooltip.hover.left="ticket.commentaires.length + ' observations'"
                      no-body
                      class="p-0 m-0"
                    >
                      <b-media-aside
                        vertical-align="center"
                        style="margin-right:5px;"
                      >
                        <feather-icon
                          icon="MessageSquareIcon"
                          size="16"
                        />
                      </b-media-aside>

                      <b-media-body class="d-flex flex-column justify-content-center align-items-start">
                        <p
                          class="mb-0 pb-0 todo-text-meta"
                          style="margin-top:2px;"
                        >
                          {{ ticket.commentaires.length }}
                        </p>
                      </b-media-body>
                    </b-media>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </draggable>
        <draggable
          v-else
          v-model="tickets"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="ticket in tickets"
            :key="ticket.id"
            class="todo-item compact mb-1"
            :class="{ 'completed': ticket.statut.est_resolu }"
            :style="'padding-left:0px'"
            @click="handleTicketClick(ticket)"
          >
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div
                  v-b-tooltip.hover.top="'Priorité : ' + ticket.priorite.titre"
                  :style="ticket.statut.est_resolu ? 'width:2px;height:100%;background-color:transparent' : 'width:3px;height:100%;background-color:' + ticket.priorite.couleur "
                />
                <div class="title-wrapper">

                  <div class="todo-title compact">
                    {{ ticket.titre }} <span
                      v-if="ticket.chambre"
                      class="font-small-2"
                    >- Chambre {{ ticket.chambre }}</span>
                  </div>
                </div>
              </div>
              <div
                class="todo-item-action compact justify-content-around"
                :class="{ 'completed': ticket.statut.est_resolu }"
              >
                <small class="mr-1">
                  {{ ticket.extra_field }}
                </small>
                <!--                <small v-if="ticket.chambre" class="">
                  Chambre : {{ ticket.chambre }}
                </small>-->
                <b-badge
                  variant="dark"
                  class="mx-1"
                  :style="'background-color:' + ticket.statut.couleur"
                >
                  {{ ticket.statut.titre }}
                </b-badge>
                <small
                  v-b-tooltip.hover.top="'Ajouté le ' + formatDateTime(ticket.created_at, { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'})"
                  class="text-right mr-1"
                  style="width:100px"
                >
                  <time-ago
                    :refresh="60"
                    :datetime="ticket.created_at"
                    locale="fr"
                    long
                    tooltip
                  />
                </small>
                <b-avatar
                  v-if="ticket.assigne_user"
                  v-b-tooltip.hover.top="'Traité par ' + ticket.assigne_user.prenom ? ticket.assigne_user.prenom : '' + ' ' + ticket.assigne_user.nom ? ticket.assigne_user.nom : ''"
                  size="30"
                  variant="primary"
                  :text="ticket.assigne_user.prenom ? ticket.assigne_user.prenom.slice(0,1) : '' + ticket.assigne_user.nom ? ticket.assigne_user.nom.slice(0,1) : ''"
                  class="mr-1"
                />
                <b-avatar
                  v-if="!ticket.assigne_user"
                  v-b-tooltip.hover.top="'Ticket non traité'"
                  size="30"
                  variant="secondary"
                  class="mr-1"
                />
              </div>
            </div>
          </li>
        </draggable>
        <div
          v-if="!isLoading"
          class="no-results"
          :class="{'show': !tickets.length}"
        >
          <h5>Pas de résultats</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :ticket="ticket"
      :clear-ticket-data="clearTicketData"
      :statuts="statuts"
      :priorites="priorites"
      :esttraites="esttraites"
      @remove-ticket="confirmDelete"
      @add-ticket="addTicket"
      @update-ticket="updateTicket"
    />

    <ticket-view-sidebar
      v-model="isTicketViewSidebarActive"
      :ticket="ticket"
      :clear-ticket-data="clearTicketData"
    />
    <b-modal
      ref="statusModal"
      no-close-on-backdrop
      centered
      title="Changer le statut du ticket"
      header-bg-variant="dark"
      ok-title="Valider"
      cancel-title="Annuler"
      @close="closeSelectedStatus"
      @ok="validateSelectedStatus"
      @cancel="closeSelectedStatus"
    >
      <b-form>
        <b-form-group
          label="Nouveau statut"
          label-for="vue-select"
        >
          <v-select
            v-model="activeOriginalTicket"
            v-b-tooltip.hover.right="'Changer le statut du ticket'"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statuts"
            :filterable="false"
            :searchable="false"
            input-id="statut"
            label="titre"
            :clearable="false"
          >
            <template
              #option="{ titre, couleur }"
              class="todo-status"
            >
              <span
                class="px-1 todo-status"
                :style="'color:'+couleur+'!important;'"
              > {{ titre }}</span>
            </template>
            <template #selected-option="{ titre, couleur }">
              <div>

                <span :style="`color:${couleur}`">{{ titre }}</span>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      ref="filterModal"
      no-close-on-backdrop
      centered
      title="Filtrer les tickets"
      header-bg-variant="dark"
      ok-title="Valider"
      cancel-title="Annuler"
      @close="closeSelectedStatus"
      @ok="validateSelectedStatus"
      @cancel="closeSelectedStatus"
    />

    <b-modal
      ref="detailsModal"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      title="Détails du ticket"
      header-bg-variant="dark"
      cancel-title="Annuler"
      @close="closeSelectedStatus"
      @cancel="closeSelectedStatus"
    >
      <b-tabs
        v-if="ticket.id"
        fill
        class="details-ticket-tabs"
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Détails</span>
          </template>
          <div class="p-0">
            <b-card
              class="shadow-none"
            >
              <div class="mb-2">
                <h3 class="mb-0 pb-0">
                  {{ ticket.titre }}
                </h3>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="blog-content"
                v-html="ticket.description"
              />
              <!-- user commnets -->
              <hr class="my-2">
              <div class="d-flex align-items-center w-100 justify-content-around">
                <b-media
                  no-body
                  class="mr-3"
                  vertical-align="center"
                >
                  <b-media-aside
                    class="mr-1"
                    vertical-align="center"
                  >
                    <b-avatar
                      rounded
                      variant="light-primary"
                      size="34"
                    >
                      <font-awesome-icon icon="fa-solid fa-bed" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <small class="font-small-1">Chambre</small>
                    <h6 class="mb-0">
                      {{ ticket.chambre }}
                    </h6>
                  </b-media-body>
                </b-media>
                <b-media
                  no-body
                  vertical-align="center"
                  class="mr-3"
                >
                  <b-media-aside
                    class="mr-1"
                    vertical-align="center"
                  >
                    <b-avatar
                      rounded
                      variant="light-primary"
                      size="34"
                    >
                      <font-awesome-icon icon="fa-solid fa-screwdriver-wrench" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <small class="font-small-1">Service</small>
                    <h6 class="mb-0">
                      {{ ticket.extra_field }}
                    </h6>
                  </b-media-body>
                </b-media>
                <b-media
                  no-body
                  vertical-align="center"
                >
                  <b-media-aside
                    class="mr-1"
                    vertical-align="center"
                  >
                    <b-avatar
                      rounded
                      variant="light-primary"
                      size="34"
                    >
                      <font-awesome-icon icon="fa-solid fa-screwdriver-wrench" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <small class="font-small-1">Traité par</small>
                    <h6 class="mb-0">
                      <span v-if="ticket.assigne_user"> {{ ticket.assigne_user.name }}</span>
                    </h6>
                  </b-media-body>
                </b-media>
              </div>
              <!-- eslint-enable -->
              <hr class="my-2">

              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-1">
                    <b-link>
                      <div class="text-body">
                        Priorité : <span
                          class="bullet bullet-sm ml-1"
                          :style="`background-color:${ticket.priorite.couleur}`"
                        /> {{ ticket.priorite.titre }}
                      </div>
                    </b-link>
                  </div>
                </div>

                <!-- dropdown -->
                <div class="blog-detail-share">
                  Staut : <b-badge
                    variant="dark"
                    class="mx-1"
                    :style="'background-color:' + ticket.statut.couleur"
                  >
                    {{ ticket.statut.titre }}
                  </b-badge>
                </div>
                <!--/ dropdown -->
              </div>
              <hr class="my-2">
              <b-media
                no-body
                class="text-center w-100"
              >
                <b-media-body>
                  <small class="text-muted mr-25">créé par</small>
                  <small>
                    <b-link class="text-body">{{ ticket.created_by.name }} <small class="text-muted ml-25 mr-25">le</small> {{ formatDateTime(ticket.created_at, { month: 'long', year: 'numeric', day: 'numeric'}) }} <small class="text-muted ml-25 mr-25">à</small> {{ formatDateTime(ticket.created_at, { hour: 'numeric', minute: 'numeric'}) }}</b-link>
                  </small>
                </b-media-body>
              </b-media>
            </b-card>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Observations ({{ ticket.commentaires.length }})</span>
          </template>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPSComment"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
              style="height:45vh"
            >
              <div
                v-if="ticket.commentaires.length > 0"
                class="chats"
              >
                <div
                  v-for="msgGrp in ticket.commentaires"
                  :key="msgGrp.id"
                  class="chat"
                  :class="'chat-left'"
                >
                  <div class="chat-avatar">
                    <b-avatar
                      v-b-tooltip.hover
                      size="36"
                      class="avatar-border-2 box-shadow-1"
                      variant="primary"
                      :text="avatarText(msgGrp.created_by.name)"
                      :title="msgGrp.created_by.name"
                    />
                  </div>
                  <div class="chat-body">
                    <div
                      class="chat-content"
                    ><small>{{ msgGrp.created_by.name }}, le {{ formatDateTime(msgGrp.created_at, { month: 'long', day: 'numeric'}) }} à {{ formatDateTime(msgGrp.created_at, { hour: 'numeric', minute: 'numeric'}) }}</small>
                      <p class="font-weight-bold mt-1">
                        {{ msgGrp.contenu }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                pas de commentaire
              </div>
            </vue-perfect-scrollbar>
          </section>
        </b-tab>
      </b-tabs>
      <b-button
        variant="dark"
        class="mt-3 pull-right float-right"
        @click="hideModal"
      >
        Fermer
      </b-button>
    </b-modal>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        :ticket-statuts="statuts"
        :ticket-priorites="priorites"
        :esttraites="esttraites"
        :atraiter="atraiter"
        :encours="encours"
        :tickets="tickets"
        :all="all"
        :resolus="resolus"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :is-ticket-view-sidebar-active.sync="isTicketViewSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BOverlay, BSpinner, BBadge,
  BMedia, BMediaAside, BMediaBody, BAvatar, VBTooltip, BModal, BForm, BFormGroup, BButton, BTabs, BTab, BCard, BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueContext from 'vue-context'
import { TimeAgo } from 'vue2-timeago'
import draggable from 'vuedraggable'
import { formatDateTime, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import ticketsStoreModule from './ticketsStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import TicketViewSidebar from './TicketViewSidebar.vue'
import { codeSimple } from '@/views/extensions/context-menu/code'

export default {
  components: {
    BForm,
    BFormGroup,
    BBadge,
    BModal,
    BMedia,
    BLink,
    BCard,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BAvatar,
    BOverlay,
    BButton,
    BTabs,
    BTab,
    FontAwesomeIcon,
    vSelect,
    draggable,
    VuePerfectScrollbar,
    DateRangePicker,
    TimeAgo,
    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
    TicketViewSidebar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      menuData: [
        { icon: 'PlusIcon', text: 'Modifier le statut' },
      ],
      codeSimple,
      scrollContent: [
        'Biscuit powder jelly beans. Lollipop candy canes croissant icing chocolate cake. Cake fruitcake powder pudding pastry.',
        'Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder.',
        'Chocolate cake icing tiramisu liquorice toffee donut sweet roll cake. Cupcake dessert icing dragée dessert. Liquorice jujubes cake tart pie donut. Cotton candy candy canes lollipop liquorice chocolate marzipan muffin pie liquorice.',
        'Powder cookie jelly beans sugar plum ice cream. Candy canes I love powder sugar plum tiramisu. Liquorice pudding chocolate cake cupcake topping biscuit. Lemon drops apple pie sesame snaps tootsie roll carrot cake soufflé halvah. Biscuit powder jelly beans. Lollipop candy canes croissant icing chocolate cake. Cake fruitcake powder pudding pastry.',
        'Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder.',
        'Chocolate cake icing tiramisu liquorice toffee donut sweet roll cake. Cupcake dessert icing dragée dessert. Liquorice jujubes cake tart pie donut. Cotton candy candy canes lollipop liquorice chocolate marzipan muffin pie liquorice.',
        'Powder cookie jelly beans sugar plum ice cream. Candy canes I love powder sugar plum tiramisu. Liquorice pudding chocolate cake cupcake topping biscuit. Lemon drops apple pie sesame snaps tootsie roll carrot cake soufflé halvah. Biscuit powder jelly beans. Lollipop candy canes croissant icing chocolate cake. Cake fruitcake powder pudding pastry.',
        'Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder.',
        'Chocolate cake icing tiramisu liquorice toffee donut sweet roll cake. Cupcake dessert icing dragée dessert. Liquorice jujubes cake tart pie donut. Cotton candy candy canes lollipop liquorice chocolate marzipan muffin pie liquorice.',
        'Powder cookie jelly beans sugar plum ice cream. Candy canes I love powder sugar plum tiramisu. Liquorice pudding chocolate cake cupcake topping biscuit. Lemon drops apple pie sesame snaps tootsie roll carrot cake soufflé halvah. Biscuit powder jelly beans. Lollipop candy canes croissant icing chocolate cake. Cake fruitcake powder pudding pastry.',
        'Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder.',
        'Chocolate cake icing tiramisu liquorice toffee donut sweet roll cake. Cupcake dessert icing dragée dessert. Liquorice jujubes cake tart pie donut. Cotton candy candy canes lollipop liquorice chocolate marzipan muffin pie liquorice.',
        'Powder cookie jelly beans sugar plum ice cream. Candy canes I love powder sugar plum tiramisu. Liquorice pudding chocolate cake cupcake topping biscuit. Lemon drops apple pie sesame snaps tootsie roll carrot cake soufflé halvah.',
      ],
      selected: 'USA',
      option: ['USA', 'Canada', 'Maxico'],
    }
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'horizontal')
  },
  setup(props, context) {
    const listViewMode = ref(localStorage.getItem('listViewMode'))
    const userData = JSON.parse(localStorage.getItem('userData'))
    moment.locale('fr')
    const isAdmin = Array.isArray(userData.roles) && (userData.roles.includes('ROLE_ADMIN') || userData.roles.includes('ROLE_MAINTENANCE') || userData.roles.includes('ROLE_SUPERADMIN'))
    const TICKETS_APP_STORE_MODULE_NAME = 'app-tickets'

    // Register module
    if (!store.hasModule(TICKETS_APP_STORE_MODULE_NAME)) store.registerModule(TICKETS_APP_STORE_MODULE_NAME, ticketsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TICKETS_APP_STORE_MODULE_NAME)) store.unregisterModule(TICKETS_APP_STORE_MODULE_NAME)
    })

    const onScroll = e => {
      console.log(e)
    }

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    const isLoading = ref(true)

    const contextMenuData = ref([
      { icon: 'PlusIcon', text: 'Changer le statut' },
    ])

    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTickets()
    })
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const ranges = {
      'Ajourd\'hui': [moment().toDate(), moment().toDate()],
      Hier: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
      '30 derniers jours': [moment().subtract(30, 'days').toDate(), moment().toDate()],
      'Ce mois-ci': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
      'Le mois dernier': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      'Cette année': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
    }
    const daterangepickerlocale = {
      direction: 'ltr',
      format: 'mm/dd/yyyy',
      separator: ' - ',
      applyLabel: 'Valider',
      cancelLabel: 'Annuler',
      weekLabel: 'W',
      customRangeLabel: 'Autre Période',
      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      monthNames: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      firstDay: 1,
    }
    const tickets = ref([])

    const statuts = ref([])

    const priorites = ref([])

    const esttraites = ref(0)
    const resolus = ref(0)
    const all = ref(0)
    const atraiter = ref(0)
    const encours = ref(0)

    const defaultMonth = new Date().getMonth() + 1

    const dateRange = ref({
      startDate: moment().subtract(30, 'days').toDate(),
      endDate: moment().toDate(),
    })

    const detailsModal = ref(null)

    const filterMonth = ref(new Date().getMonth() + 1)
    const filterYear = ref(new Date().getFullYear())

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]

    const sortBy = ref(routeSortBy.value)

    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })

    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    }

    const blankTicket = {
      id: null,
      titre: '',
      extra_field: '',
      dueDate: new Date(),
      description: '',
      assigneUser: null,
      statut: null,
      priorite: null,
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
      chambre: '',
    }

    const ticket = ref(JSON.parse(JSON.stringify(blankTicket)))

    const range = ref(null)

    const menuContext = ref(null)

    // Search Query
    const searchQuery = ref(routeQuery.value)

    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTickets())

    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const clearTicketData = () => {
      ticket.value = JSON.parse(JSON.stringify(blankTicket))
    }

    const fetchTickets = () => {
      isLoading.value = true
      store.dispatch('app-tickets/fetchTickets', {
        q: searchQuery.value,
        filter: router.currentRoute.params.filter,
        statut: router.currentRoute.params.statut,
        priorite: router.currentRoute.params.priorite,
        sortBy: sortBy.value,
        /*  month: filterMonth.value,
        year: filterYear.value, */
        start: dateRange.value.startDate,
        end: dateRange.value.endDate,
        skip: 1,
        limit: 800,
      })
        .then(response => {
          tickets.value = response.data.data
          isLoading.value = false
          esttraites.value = response.data['est-traite']
          all.value = response.data.tous
          resolus.value = response.data.resolus
          atraiter.value = response.data['a-traites']
          encours.value = response.data.encours
        })
    }

    const fetchStatuts = () => {
      isLoading.value = true
      store.dispatch('app-tickets/fetchStatuts', {
        skip: 1,
        limit: 10,
      })
        .then(response => {
          statuts.value = response.data.data.map(item => ({
            titre: item.titre, id: item.id, couleur: item.couleur, route: { name: 'apps-tickets-statut', params: { statut: item.id } },
          }))
        })
    }

    const fetchPriorites = () => {
      isLoading.value = true
      store.dispatch('app-tickets/fetchPriorites', {
        skip: 1,
        limit: 10,
      })
        .then(response => {
          priorites.value = response.data.data.map(item => ({
            titre: item.titre, id: item.id, couleur: item.couleur, route: { name: 'apps-tickets-priorite', params: { priorite: item.id } },
          }))
          // eslint-disable-next-line prefer-destructuring
          blankTicket.priorite = priorites.value[0]
          ticket.value = JSON.parse(JSON.stringify(blankTicket))
        })
    }

    const addTicket = val => {
      store.dispatch('app-tickets/addTicket', val)
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ticket ajouté avec succès',
              variant: 'success',
              text: '',
            },
          })
          // eslint-disable-next-line no-use-before-define
          fetchTickets()
        })
    }

    const removeTicket = ticketData => {
      store.dispatch('app-tickets/removeTicket', { id: ticketData })
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ticket supprimé avec succès',
              variant: 'success',
              text: '',
            },
          })
          // eslint-disable-next-line no-use-before-define
          fetchTickets()
        })
    }

    const confirmDelete = () => {
      const ticketId = ticket.value.id
      context.root.$swal({
        title: 'Supprimer le ticket ?',
        text: 'Confirmez-vous la suppression du ticket ? Cette action est irréversible.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer !',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          removeTicket(ticketId)
        }
      })
    }

    const updateTicket = ticketData => {
      store.dispatch('app-tickets/updateTicket', { ticket: ticketData })
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ticket modifié avec succès',
              variant: 'success',
              text: '',
            },
          })
          // eslint-disable-next-line no-use-before-define
          fetchTickets()
        })
    }

    const updateTicketStatus = (ticketData, status) => {
      store.dispatch('app-tickets/updateTicketStatus', { ticket: status.value.id, status: ticketData.value.id })
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ticket modifié avec succès',
              variant: 'success',
              text: '',
            },
          })
          // eslint-disable-next-line no-use-before-define
          fetchTickets()
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const isTicketViewSidebarActive = ref(false)

    const resolveStatutVariant = tag => {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'update') return 'info'
      return 'primary'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    fetchStatuts()
    fetchPriorites()
    fetchTickets()
    setInterval(() => {
      fetchTickets()
    }, 60000)

    const onChangeYear = e => {
      console.log(e)
      range.value = e
      /* filterYear.value = e.year
      filterMonth.value = e.monthIndex */
      fetchTickets()
    }

    // Single Ticket isCompleted update
    const updateTicketIsCompleted = ticketData => {
      // eslint-disable-next-line no-param-reassign
      ticketData.isCompleted = !ticketData.isCompleted
      updateTicket(ticketData)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const start = new Date()
    const end = new Date()
    const timerEnd = ref(end.setSeconds(end.getSeconds() + 60))
    const timerStart = ref(start)
    const timerDuration = 60
    const handleTimeExpire = () => {
      fetchTickets()
      const newEnd = new Date()
      timerEnd.value = newEnd.setSeconds(newEnd.getSeconds() + 60)
    }

    const activeTicket = ref(JSON.parse(JSON.stringify(blankTicket)))
    const activeOriginalTicket = ref(JSON.parse(JSON.stringify(blankTicket.statut)))

    const statusModal = ref(null)

    const filterModal = ref(null)

    const openStatusModal = ticketActive => {
      activeTicket.value = ticketActive
      ticket.value = ticketActive
      activeOriginalTicket.value = ticketActive.statut
      if (isAdmin) {
        statusModal.value.show()
      } else {
        isTicketViewSidebarActive.value = true
      }
    }

    const openDetailsModal = ticketActive => {
      activeTicket.value = ticketActive
      ticket.value = ticketActive
      if (!isAdmin) {
        detailsModal.value.show()
      } else {
        isTaskHandlerSidebarActive.value = true
      }
    }

    const handleTicketClick = ticketData => {
      ticket.value = ticketData
      activeTicket.value = ticketData
      if (isAdmin) {
        isTaskHandlerSidebarActive.value = true
      } else {
        detailsModal.value.show()
      }
    }

    const openFilterModal = () => {
      filterModal.value.show()
    }

    const setSelectedStatus = val => {
      /* activeTicket.value.status = val */
      activeOriginalTicket.value.status = val
    }

    const resetTicketLocal = () => {
      activeOriginalTicket.value = activeTicket.value.statut
    }

    const closeSelectedStatus = () => {
      resetTicketLocal()
    }

    const changeViewMode = () => {
      if (localStorage.getItem('listViewMode') === 'detailed') {
        localStorage.setItem('listViewMode', 'compact')
        listViewMode.value = 'compact'
      } else {
        localStorage.setItem('listViewMode', 'detailed')
        listViewMode.value = 'detailed'
      }
    }

    const validateSelectedStatus = () => {
      context.root.$swal({
        title: 'Modifier le statut ?',
        text: 'Confirmez-vous la modification du statut ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, modifier !',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          updateTicketStatus(activeOriginalTicket, activeTicket)
        }
      })
    }

    return {
      ticket,
      tickets,
      esttraites,
      all,
      atraiter,
      encours,
      resolus,
      statuts,
      priorites,
      removeTicket,
      confirmDelete,
      addTicket,
      updateTicket,
      clearTicketData,
      searchQuery,
      fetchTickets,
      fetchStatuts,
      fetchPriorites,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,
      range,
      userData,
      isAdmin,
      onScroll,
      contextMenuData,
      defaultMonth,
      filterMonth,
      filterYear,
      dateRange,
      ranges,
      daterangepickerlocale,
      VueContext,
      isLoading,
      // UI
      resolveStatutVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,
      isTicketViewSidebarActive,

      // Click Handler
      handleTicketClick,

      // Filters
      formatDateTime,
      avatarText,
      menuContext,
      // Single Ticket isCompleted update
      updateTicketIsCompleted,

      onChangeYear,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      timerStart,
      timerDuration,
      timerEnd,
      handleTimeExpire,
      moment,
      activeTicket,
      openStatusModal,
      openFilterModal,
      openDetailsModal,
      statusModal,
      filterModal,
      detailsModal,
      setSelectedStatus,
      closeSelectedStatus,
      activeOriginalTicket,
      validateSelectedStatus,
      listViewMode,
      changeViewMode,
    }
  },
  methods: {
    optionClicked(text, icon) {
      this.$refs['my-modal'].show()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `You have click on ${text}!`,
          icon,
        },
      })
    },
    showModal() {
      /* this.$refs['my-modal'].show() */
      this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
    },
    /* showStatusModal(ticket) {
      activeTicket.value = ticket
      this.$refs['my-modal'].show()
    }, */
    hideModal() {
      this.$refs.detailsModal.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '~vue2-daterange-picker/dist/vue2-daterange-picker.css';
@import '@core/scss/vue/libs/vue-context.scss';
</style>
